<template>
  <div class="vehicle-card">
    <div class="ymms">
      <router-link
        :to="`/detail/${vehicle.YMMS_Desc}`"
        custom
      >
        <h2>{{ vehicle.Year }} {{ vehicle.Make }} {{ vehicle.Model }}</h2>
        <h3>{{ vehicle.Trim }}</h3>
      </router-link>
    </div>
    <router-link
      :to="`/detail/${vehicle.YMMS_Desc}`"
      custom
    >
      <vehicle-image
        :vehicle="vehicle"
        class="vehicle-image img-fluid"
        imgType="exterior"
        imgSizeType="wide"
      />
    </router-link>
    <div class="vehicle-summary">
      <span class="price">{{ toCurrency(vehicle.Price) }}</span>
      <span class="range">Range: {{ vehicle.Range }} miles</span>
      <span class="seats">Seats: {{ vehicle.Seating }}</span>
    </div>
    <div class="vehicle-links">
      <div class="link">
        <router-link
          :to="`/detail/${vehicle.YMMS_Desc}`"
          custom
          v-slot="{ navigate }"
        >
          <button
            @click="navigate"
            class="btn button large learn-more-link"
            :class="{ tertiary: vehicle.rank != 1 }"
            role="link"
          >
            learn more<span class="accent">&nbsp;&raquo;</span>
          </button>
        </router-link>
      </div>
    </div>
    <div v-if="showDetail" class="vehicle-detail">
      <div class="feature-bar" v-for="(feature, $idx) in features" :key="$idx">
        <h4 class="d-inline">{{ feature.name }}</h4>
        <score-label
          class="score-label"
          :score="vehicle[`${feature.name}_Score`]"
          :reverse="feature.reverse"
        />
        <feature-score
          class="bar"
          :vehicle="vehicle"
          :featureName="feature.name"
          :hideLabel="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
const VehicleImage = () => import("Components/VehicleImage");
const FeatureScore = () => import("Components/Compare/FeatureScore");
const ScoreLabel = () => import("Components/ScoreLabel");

import common from "Mixins/common";

export default {
  data() {
    return {
      features: [
        { name: "Cost", reverse: true },
        { name: "Performance", reverse: false },
        { name: "Safety", reverse: false },
        { name: "Infotainment", reverse: false },
        { name: "Reliability", reverse: false },
        { name: "Cargo", reverse: false },
        { name: "Charging", reverse: false },
      ],
    };
  },
  props: {
    showDetail: {
      type: Boolean,
      default: false,
    },
    vehicle: {
      type: Object,
      required: true,
    },
  },

  components: {
    VehicleImage,
    FeatureScore,
    ScoreLabel,
  },

  methods: {
    // add any methods here
  },
  mixins: [common],
};
</script>

<style lang="scss" scoped>
.vehicle-card {
  background-color: white;
  box-sizing: border-box;
  box-shadow: 0px 8px 6px 0px rgba(0, 0, 0, 0.2);
  padding: 10px;

  .ymms {
    @media (max-width: 1300px) {
      min-height: 70px;
    }
    @include media-breakpoint-down(md) {
      min-height: auto;

      @media (max-width: 600px) {
        min-height: 70px;
      }
    }
  }

  h2 {
    margin-bottom: 0;
    font-size: 1.425rem;
    color: #3b9bf5;
    font-weight: 800;
  }

  h3 {
    color: black;
    font-size: 0.875rem;
    font-weight: normal;
    min-height: 0.875rem;
  }

  span {
    margin-right: 10px;
    color: #5a5a5a;
    font-size: 0.95rem;

    &.price {
      font-weight: 800;
    }
  }
}

.vehicle-summary {
  margin-top: 8px;
  margin-bottom: 10px;

  .range,
  .seats {
    font-size: 0.75rem;
    color: $light-grey;
  }
}

.vehicle-links {
  width: 100%;

  div.link {
    font-size: 0.75rem;

    button.btn {
      width: 100%;
    }
  }
}

.vehicle-detail {
  .feature-bar {
    padding-bottom: 10px;

    .bar {
      width: 98%;
      display: inline-block;
      margin-right: 10px;

      @include media-breakpoint-down(md) {
        width: 100%;
        padding-bottom: 0;
      }
    }

    .score-label {
      font-size: 0.7rem;
      color: #162b32;
      display: inline;
      font-weight: 700;
      float: right;
      padding-bottom: 0;
      position: relative;
      top: 5px;
    }
  }

  margin-top: 20px;

  h4 {
    color: #333333;
    font-size: 0.75rem;
    margin-bottom: 0;
  }
}
</style>
